<template>
  <v-sheet class="fluid-window back-img">
    <div class="transparent content-top-padding">
      <h1 class="white--text text-center client-title-div mb-8">
        <i18n path="detail.services" />
      </h1>
    </div>
    <v-row justify="center" class="mx-2">
      <active-service :service="i" v-for="i in items" :key="i.idx" />
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "Services",
  components: {
    "active-service": () => import("../components/ActiveServiceCard.vue"),
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$api.services().then((response) => {
      try {
        this.items = [...response];
      } catch (error) {
        this.items = [];
      }
    });
  },
};
</script>
<style scoped>
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
</style>
